import * as amplitude from '@amplitude/analytics-browser';
import styled from '@emotion/styled';
import { Anchor } from 'components/atoms/Anchor';
import mediaQueries from 'hooks/Media';

interface Props {
  className?: string;
}

export default function DownloadButton({ className }:Props) {
  const handleIOSDownload = () => {
    amplitude.logEvent('IOS Down Click');
  };

  const handleAOSDownload = () => {
    amplitude.logEvent('AOS Down Click');
  };

  return (
    <DownloadButtonContainer className={className}>
      <DownloadButtonContent>
        <Anchor
          text="Download for IOS"
          href="https://ttmik.me/48ePOKV"
          imgUrl="/asset/svg/ios.svg"
          alt="apple"
          imgWidth={23}
          imgHeight={28}
          onClick={handleIOSDownload}
        />
        <Anchor
          text="Download for Android"
          href="https://ttmik.me/48AMEAP"
          imgUrl="/asset/svg/android.svg"
          alt="android"
          imgWidth={24}
          imgHeight={26}
          onClick={handleAOSDownload}
        />
      </DownloadButtonContent>
    </DownloadButtonContainer>
  );
}

const DownloadButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const DownloadButtonContent = styled.div`
  display: flex;
  gap: 16px;
  ${mediaQueries.tablet}{
    flex-direction: column;
    margin-top: 16px;
  }
`;
