import styled from '@emotion/styled';
import Image from 'components/atoms/Image';
import SectionText from 'components/molecules/SectionText';
import { orange } from 'core/ColorList';
import mediaQueries from 'hooks/Media';

export default function LearningValue3() {
  return (
    <LearningValue3Container>
      <Image src="/asset/images/audio-screen.webp" className="audioScreenImg" alt="app screen" />
      <SectionText
        number={3}
        title={(
          <h3>
            Read along with
            <br />
            an audio track
          </h3>
        )}
        text={(
          <p>
            Learn more efficiently by reading and
            <br className="pc" />
            {' '}
            listening to the stories.
          </p>
        )}
        color={orange}
      />
    </LearningValue3Container>
  );
}

const LearningValue3Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 213px;
  ${mediaQueries.tablet}{
    flex-direction: column;
    align-items: flex-start;
    margin-top: 120px;
    gap: 24px;
  }
  .audioScreenImg{
    width: 555px;
    height: 650px;
    padding-right: 120px;
    ${mediaQueries.desktop}{
      display: flex;
      align-items: center;
      padding-right: 40px;
    }
    ${mediaQueries.tablet}{
      margin-left: -82px;
      padding-right: 0;
      width: 400px;
      height: 476px;
    }
  }
`;
