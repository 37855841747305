import NewsLetterUnsubscribedPage from 'pages/NewsLetterUnsubscribedPage';
import { Route, RouteProps, Routes } from 'react-router-dom';
import ErrorBoundary from '../components/extra/ErrorBoundary';
import LandingPage from '../pages/LandingPage';

// route
const RouteSet:RouteProps[] = [
  {
    path: '/news-letter/unsubscribed',
    element: <NewsLetterUnsubscribedPage />,
  },
  {
    path: '/*',
    element: <LandingPage />,
  }];
function EntryRoute() {
  return (
    <ErrorBoundary>
      <Routes>
        {
      RouteSet.map((route, index) => (
        <Route
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          path={route.path}
          element={route.element}
        />
      ))
      }
      </Routes>
    </ErrorBoundary>
  );
}

export default EntryRoute;
