import * as amplitude from '@amplitude/analytics-browser';
import styled from '@emotion/styled';
import Image from 'components/atoms/Image';
import DownloadButton from 'components/molecules/DownloadButton';
import { lavender, white } from 'core/ColorList';
import mediaQueries from 'hooks/Media';

export default function Download() {
  const handleMobileDownload = () => {
    amplitude.logEvent('Mobile Down Click');
  };

  return (
    <DownloadContainer>
      <strong>Start your free trial now!</strong>
      <span className="pc">Scan QR code to download</span>
      <Image src="/asset/svg/qr.svg" className="qrImg" alt="qr"  />
      <div className="pc downloadBox">
        <DownloadButton />
      </div>
      <div className="mo downloadBox">

        <DownloadMobile
          href="https://onelink.to/jpc4pn"
          target="_blank"
          rel="noopener noreferrer"
          onClick={handleMobileDownload}
        >
          Download App
          <Image src="/asset/svg/download.svg" className="downloadImg" alt="" />
        </DownloadMobile>
      </div>
    </DownloadContainer>
  );
}

const DownloadContainer = styled.div`
    text-align: center;
    color: ${white};
    strong{
      display: block;
      font-size: 32px;
      font-weight: 600;
      ${mediaQueries.tablet}{
        font-size: 24px;
      }
    }
    span{
      display: block;
      margin-top: 64px;
      font-size: 18px;
    }
    p{
        margin-top: 100px;
    }
    .qrImg{
      margin: 32px auto 0;
      width: 137px;
      height: 137px;
      ${mediaQueries.tablet}{
        display: none;
      }
    }
    .downloadBox{
      margin-top: 64px;
      ${mediaQueries.tablet}{
        margin-top: 32px;
      }
    }
`;

const DownloadMobile = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  font-size: 20px;
  font-weight: 700;
  height: 70px;
  background: ${lavender};
  border-radius: 35px;
  img{
    width: 23px;
  }
`;
