import styled from '@emotion/styled';
import Image from 'components/atoms/Image';
import SectionText from 'components/molecules/SectionText';
import { purple } from 'core/ColorList';
import mediaQueries from 'hooks/Media';

export default function LearningValue4() {
  const list = [
    'Series', 'Drama', 'Romance', 'Action', 'Suspense', 'Fantasy', 'Comedy',
    'Articles', 'History', 'Art', 'Culture', 'Politics', 'Science', 'Society',
    'Technology', 'Education', 'Health', 'Sports', 'Food', 'Psychology'
  ];
  return (
    <LearningValue4Container>
      <Image src="/asset/images/cloude.webp" className="cludeImg" alt="" />
      <SectionText
        number={4}
        className="zIndexLayer"
        title={(
          <h3>
            Take quizzes after
            <br />
            every story
          </h3>
        )}
        text={(
          <p>
            See your progress with the review quizzes.
          </p>
        )}
        color={purple}
      />
      <Image src="/asset/images/pc-quiz-screen.webp" className="quizImg pc" alt="app screen" />
      <Image src="/asset/images/mo-quiz-screen.webp" className="quizImg mo" alt="app screen" />
    </LearningValue4Container>
  );
}

const LearningValue4Container = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: 177px;
  ${mediaQueries.tablet}{
    flex-direction: column-reverse;
    align-items: flex-start;
    margin-top: 120px;
    gap: 24px;
  }
  .cludeImg{
    position: absolute;
    top: -50px;
    left: -282px;
    width: 591px;
    height: 320px;
    ${mediaQueries.desktop}{
      width: 400px;
      left: -96px;
    }
    ${mediaQueries.tablet}{
      display: none;
    }
  }
  .zIndexLayer{
    position: relative;
    z-index: 1;
    margin-bottom: 110px;
    ${mediaQueries.tablet}{
      width:100%;
      margin-bottom: 0;
    }
  }
  .quizImg{
    position: relative;
    display: flex;
    align-items: center;
    width: 552px;
    height: 573px;
    ${mediaQueries.tablet}{
      margin-left: -60px;
      width: 376px;
      height: 468px;
    }
  }
`;
