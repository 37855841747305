import styled from '@emotion/styled';
import { gray } from 'core/ColorList';
import { ReactNode, useState } from 'react';

interface Props {
  title: string;
  desc: ReactNode;
}

function DropDown({ title, desc }:Props) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <DropDownContainer onClick={() => setIsOpen(!isOpen)}>
      <FlexRowJustifyBetween>
        <span>Q.</span>
        <DropDownTitle>{title}</DropDownTitle>
        <RotateImage
          src="/asset/svg/qna-button.svg"
          alt={isOpen ? 'close button' : 'open button'}
          isOpen={isOpen}
        />
      </FlexRowJustifyBetween>
      <HideEffect isOpen={isOpen}>
        {desc}
      </HideEffect>
    </DropDownContainer>
  );
}

const DropDownContainer = styled.div`
  cursor: pointer;
  border-bottom: 1px solid ${gray};
  padding: 30px 0;
  max-width: 744px;
  width: -webkit-fill-available;
  img {
    margin-left: 20px;
    align-self: center;
    margin: 0 0 0 auto;
  }
  &:nth-of-type(1){
    border-top: 1px solid ${gray}
  }
`;

const FlexRowJustifyBetween = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  span {
    font-size: 20px;
    line-height: 32px;
    font-weight: 500;
    font-family: "Pretendard", sans-serif;
    color: black;
  }
`;

const DropDownTitle = styled.strong`
  font-size: 20px;
  color: black;
  line-height: 28px;
  font-weight: 500;
  font-family: "Pretendard", sans-serif;
  margin: 0 8px;
  text-align: left;
`;

const RotateImage = styled.img`
  margin-left: 20px;
  align-self: center;
  margin: 0 0 0 auto;
  transition: transform 0.15s ease-out;
  transform: ${(props: { isOpen: boolean }) => (props.isOpen ? 'rotate(45deg)' : 'none')};
`;

const HideEffect = styled.div`
  display: ${(props: { isOpen: boolean }) => (props.isOpen ? 'block' : 'none')};
  p {
    padding-top: 30px;
    font-size: 18px;
    line-height: 28px;
  }
`;

export default DropDown;
