import styled from '@emotion/styled';
import Layout from 'components/base/Layout';
import SectionTitle from 'components/molecules/SectionTitle';
import LearningValue1 from 'components/organisms/LearningValue1';
import LearningValue2 from 'components/organisms/LearningValue2';
import LearningValue3 from 'components/organisms/LearningValue3';
import LearningValue4 from 'components/organisms/LearningValue4';
import LearningValue5 from 'components/organisms/LearningValue5';
import {
  lavenderGradient, primary
} from 'core/ColorList';
import mediaQueries from 'hooks/Media';

export default function LearningValueSection() {
  return (
    <LearningValueSectionContainer>
      <Layout>
        <SectionTitle
          title={(
            <h2>
              Power up your Korean
              <br className="pc" />
              {' '}
              with graded reading
            </h2>
          )}
          text={(
            <p>
              Study Korean in context at your skill level.
              <br className="mo" />
              {' '}
              Read more, learn better.
            </p>
          )}
          textColor={primary}
        />
        <LearningValue1 />
        <LearningValue2 />
        <LearningValue3 />
        <LearningValue4 />
        <LearningValue5 />
      </Layout>
    </LearningValueSectionContainer>
  );
}

const LearningValueSectionContainer = styled.section`
  margin-top:-50px;
  padding: 240px 0 0;
  background: ${lavenderGradient};
  ${mediaQueries.tablet}{
    padding: 180px 0 120px;
  }
`;
