import styled from '@emotion/styled';
import mediaQueries from 'hooks/Media';
import { ReactNode } from 'react';

interface Props {
  children?: ReactNode;
}

export default function Layout({ children }:Props) {
  return (
    <LayoutContainer>
      {children}
    </LayoutContainer>
  );
}

const LayoutContainer = styled.div`
  position: relative;
  max-width: 1128px;
  margin: 0 auto;
  ${mediaQueries.desktop}{
    max-width: 100%;
    padding: 0px 7%;
  }
`;
