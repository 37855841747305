import {
  desktop,
  mobileLandscapeWidth,
  tablet
} from '@standalone/Layout';

const mediaQueries = {
  desktop: `@media screen and (max-width: ${desktop})`,
  tablet: `@media screen and (max-width: ${tablet})`,
  mobileLandscape: `@media screen and (max-width: ${mobileLandscapeWidth})`,
};

export default mediaQueries;
