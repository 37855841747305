/* eslint-disable react/no-danger */
import styled from '@emotion/styled';
import Image from 'components/atoms/Image';
import Layout from 'components/base/Layout';
import Footer from 'components/templates/Footer';
import mediaQueries from 'hooks/Media';
import { useEffect } from 'react';

export default function NewsLetterUnsubscribedPage() {
  // https://tally.so/help/developer-resources#85b0ab91621742cdb600183f9c261fae
  useEffect(() => {
    const widgetScriptSrc = 'https://tally.so/widgets/embed.js';
    let Tally:any;

    const load = () => {
      // Load Tally embeds
      if (typeof Tally !== 'undefined') {
        Tally.loadEmbeds();
        return;
      }

      // Fallback if window.Tally is not available
      document
        .querySelectorAll('iframe[data-tally-src]:not([src])')
        .forEach((iframeEl:any) => {
          // eslint-disable-next-line no-param-reassign
          iframeEl.src = iframeEl.dataset.tallySrc;
        });
    };

    // If Tally is already loaded, load the embeds
    if (typeof Tally !== 'undefined') {
      load();
      return;
    }

    // If the Tally widget script is not loaded yet, load it
    if (document.querySelector(`script[src="${widgetScriptSrc}"]`) === null) {
      const script = document.createElement('script');
      script.src = widgetScriptSrc;
      script.onload = load;
      script.onerror = load;
      document.body.appendChild(script);
    }
  }, []);

  return (
    <div>
      <Header>
        <LogoImageWrapper>
          <Image src="/asset/images/logo_bg_white.png" />
        </LogoImageWrapper>
      </Header>
      <Divider />
      <Layout>
        <Container>
          <ImageWrapper>
            <Image src="/asset/images/check.png" alt="stories bg" />
          </ImageWrapper>
          <Title>
            You&apos;ve been unsubscribed
            <br />
            from TTMIK Stories newsletter
          </Title>
          <Content>
            Dear valued customer,
            {' '}
            <br />
            <br />
            부탁해요!
            We will continue to enhance our services to provide you with the best learning experience.
            Could you take a moment and share with us the reason for unsubscribing?
            <br />
            <br />
            Thank you for your support. We hope TTMIK Stories will continue being a part of your Korean learning adventure! 감사합니다.
            {' '}
            <br />
            <br />
            Best regards,
            <br />
            The TTMIK Stories Team
          </Content>
          <IframeWrapper>
            <iframe data-tally-src="https://tally.so/embed/mO5zLY?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1" loading="lazy" width="100%" height="394" frameBorder="0" marginHeight={0} marginWidth={0} title="TTMIK Stories newsletter" />
          </IframeWrapper>
        </Container>
      </Layout>
      <Footer />
    </div>
  );
}

const Header = styled.div`
  display:flex;
  justify-content: center;
  padding: 24px;

  ${mediaQueries.tablet} {
    padding: 20px;
  }
`;
const Divider = styled.div`
  background-color: rgba(153, 153, 153, 0.50);
  height: 1px;
`;
const LogoImageWrapper = styled.div`
  width: 84px;
  height:52px;

  ${mediaQueries.tablet} {
    width: 64px;
    height:40px;
  }
`;
const ImageWrapper = styled.div`
  height: 42px;
  width: 42px;
`;
const Container = styled.div`
  display:flex;
  flex-direction: column;
  gap: 24px;
  margin-top:120px;
  margin-bottom:120px;
  ${mediaQueries.tablet} {
    gap: 32px;
    margin-top:80px;
    margin-bottom:80px;
  }
`;
const Title = styled.div`
  color: #031B30;
  font-size: 28px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
`;
const Content = styled.div`
  color: #031B30;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 27px;
`;
const IframeWrapper = styled.div`
  border-radius: 15px;
  background: #EDE5FF;
  padding: 40px 48px 32px;
`;
