import styled from '@emotion/styled';
import Image from 'components/atoms/Image';
import Layout from 'components/base/Layout';
import SectionTitle from 'components/molecules/SectionTitle';
import SwiperSlides from 'components/molecules/SwiperSlides';
import Download from 'components/organisms/Download';
import {
  gray,
  green,
  green2,
  lavender,
  orange,
  primary,
  purple,
  white
} from 'core/ColorList';
import mediaQueries from 'hooks/Media';

export default function LearningGuideSection() {
  return (
    <LearningGuideSectionContainer>
      <Layout>
        <SectionTitle
          title={(
            <h2>
              Review
              <br className="mo" />
              {' '}
              and retain
            </h2>
          )}
          text={(
            <p>
              Solidify your learning with
              <br className="pc" />
              {' '}
              our enhanced routine for learners.
            </p>
        )}
          textColor={lavender}
        />
        <SwiperSlides />
        <Image src="/asset/images/logo.webp" className="logoImg" alt="stories logo" />
        {/* <DiscountTitle>
          Celebrate
          {' '}
          <strong>100</strong>
          {' '}
          days of TTMIK Stories
          <br className="pc" />
          {' '}
          and enjoy a special discount!
        </DiscountTitle> */}
        <DiscountPrice>
          <div className="discountInfoTitle">
            <strong>
              <span>Save 50%</span>
              {' '}
              with a
              {' '}
              <span>yearly</span>
              {' '}
              subscription!
              <br />
              {' '}
              Access all premium content for half the price.
            </strong>
          </div>
          {/* <div className="discountInfoList">
            <ul>
              <li>
                <strong>
                  Yearly
                  <br className="pc" />
                  {' '}
                  Subscription
                </strong>
                <Image className="triangle" src="/asset/svg/triangle-2.svg" alt="화살표" />
                <div className="priceBox">
                  <span className="price">30%</span>
                  <div className="period">
                    <span>OFF</span>
                    <span>for the first year!</span>
                  </div>
                </div>
              </li>
              <li>
                <strong>
                  Monthly
                  <br className="pc" />
                  {' '}
                  Subscription
                </strong>
                <Image className="triangle" src="/asset/svg/triangle-2.svg" alt="화살표" />
                <div className="priceBox">
                  <span className="price">10%</span>
                  <div className="period">
                    <span>OFF</span>
                    <span>for the first month!</span>
                  </div>
                </div>
              </li>
            </ul>
          </div> */}
          <div className="discountInfoDesc">
            <p>
              * Price may vary in some regions due to app store policies.
            </p>
          </div>
        </DiscountPrice>
        <DiscountService>
          <Image src="/asset/images/app-icons.webp" className="appIcons" alt="" />
          <strong>
            TTMIK Stories and TTMIK Course Subscription
            <br className="pc" />
            {' '}
            are two separate services.
          </strong>
          <p>
            Please subscribe to TTMIK Stories
            <br className="pc" />
            {' '}
            through in-app purchases after the trial period.
          </p>
        </DiscountService>
        <Download />
      </Layout>
      <Image src="/asset/images/bottom-banner.webp" className="bannerImg" alt="banner" />
    </LearningGuideSectionContainer>
  );
}

const LearningGuideSectionContainer = styled.section`
    padding-top: 120px;
    background: ${primary};
    ${mediaQueries.tablet}{
      padding-top: 80px;
    }
    .logoImg{
      margin: 170px auto 80px;
      width: 125px;
      height: 83px;
      ${mediaQueries.tablet}{
        margin: 150px auto 80px;
      }
    }
    .bannerImg{
      margin-top: 130px;
      margin-bottom: -2px;
      height: 754px;
      background: ${primary};
      ${mediaQueries.tablet}{
        height: 540px;
        margin-top: 0;
      }
      img{
        height: 100%;
        object-fit: cover;
        ${mediaQueries.tablet}{
          object-position: 70%;
        }
      }
    }
`;

const DiscountTitle = styled.div`
  padding: 21px 24px;
  margin: 0 auto 32px;
  width: 636px;
  text-align: center;
  background: rgba(44, 237, 144, 0.1);
  color: ${green2};
  line-height: 33px;
  font-size: 22px;
  font-weight: 500;
  border-radius: 20px;
  box-sizing: border-box;
  ${mediaQueries.tablet}{
    width: 100%;
  }
  strong{
    font-size: 32px;
  }
`;

const DiscountPrice = styled.div`
  .discountInfoTitle{
    text-align: center;
    strong{
      font-size: 32px;
      line-height: 48px;
      color: ${white};
      font-weight: 500;
      span{
        color:${green2};
      }
      ${mediaQueries.tablet}{
        font-size: 24px;
        line-height: 36px;
      }
    }
    p{
      margin-top: 70px;
      font-size: 24px;
      font-weight: 500;
      line-height: 30px;
      color: ${green2};
    }    
  }
  .discountInfoList{
    margin-top: 70px;
    ul{
      display: flex;
      flex-direction: column;
      align-items: center;
      ${mediaQueries.tablet}{
        margin-top: 80px;
      }
      li{
        display: flex;
        align-items: center;
        width: 600px;
        padding-left: 20px;
        ${mediaQueries.tablet}{
          flex-direction: column;
          padding-left: 0;
          padding-bottom: 24px;
          width: 100%;
          border-bottom: 0.5px solid ${green2};
        }
        &+li {
          margin-top: 42px;
          ${mediaQueries.tablet}{
            padding-top: 24px;
            margin-top: 0;
          }
        }
        strong{
          font-size: 24px;
          line-height: 34px;
          color:${white};
          font-weight: 600;
        }
        .triangle{
          padding: 0 98px 0 48px;
          width: 15px;
          ${mediaQueries.tablet}{
            display: none;
          }
        }
        .priceBox{
          display: flex;
          align-items: center;
          ${mediaQueries.tablet}{
              margin-top: 20px;
          }
          .price{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 82px;
            height: 82px;
            font-size: 24px;
            color: ${green2};
            border: 1px solid ${green2};
            border-radius: 50%;
          }
          .period{
            padding-left: 16px;
            font-size: 20px;
            color: ${green2};
            span{
              display: block;
              &:nth-of-type(1){
                font-weight: 600;
              }
              &:nth-of-type(2){
                margin-top: 5px;
              }
            }
          }
        }
      }
    }
  }
  .discountInfoDesc{
    margin-top: 32px;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    color: ${gray};
  }
`;

const DiscountService = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 120px 0;
  text-align: center;
  ${mediaQueries.tablet}{
    margin: 70px 0 64px;
  }
  .appIcons {
    width: 211px;
    height: 166px;
  }
  strong{
    margin-top: 16px;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    color: ${orange};
  }
  p{
    margin-top: 10px;
    font-size: 18px;
    font-weight: 300;
    line-height: 28px;
    color: ${gray}; 
  }
`;

const DiscountCoupon = styled.div`
  position: relative;
  margin: 150px 0;
  ${mediaQueries.tablet}{
    margin: 105px 0 65px;
  }
  .particleImg{
    position: absolute;
    top: -30px;
    left: 50%;
    transform: translate3d(-50%,0,0);
    width: 636px;
    ${mediaQueries.tablet}{
      top: -40px;
      width: 395px;
    }
  }
  .couponTitle {
    text-align: center;
    h3{
      font-size: 32px;
      font-weight: 500;
      line-height: 46px;
      color: ${white};
      ${mediaQueries.tablet}{
        font-size: 24px;
        line-height: 34px;
      }
    }
    p{
      margin-top: 48px;
      font-size: 24px;
      font-weight: 500;
      line-height: 30px;
      color: #AA88FF;
      ${mediaQueries.tablet}{
        font-size: 21px;
        line-height: 25px;
      }
    }
  }
  .couponBox{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 55px auto 0;
    padding: 56px 67px;
    width: 636px;
    text-align: center;
    background: ${purple};
    border-radius: 40px;
    box-sizing: border-box;
    ${mediaQueries.tablet}{
      margin: 40px auto 0;
      padding: 40px 24px;
      width: 100%;
    }
    .couponLogo{
      width: 148px;
      height: 72px;
      ${mediaQueries.tablet}{
        width: 120px;
        height: 60px;
     }
    }
    a{
      display: flex;
      align-items: center;
      margin-top: 16px;
      color: ${green};
      .moveArrow{
        width: 36px;
        height: 36px;

      }
    }
    .couponImg{
      width: 330px;
      height: 330px;
      ${mediaQueries.tablet}{
        width: 240px;
        height: 240px;
      }
    }
    p{
      font-size: 24px;
      line-height: 36px;
      color:${white};
      ${mediaQueries.tablet}{
        font-size: 21px;
        
      }
      span{
        color: ${green};
        font-weight: 600;
      }
    }
  }
  .couponText{
    padding: 0 10px;
    margin: 48px auto 0;
    width: 636px;
    font-size: 18px;
    line-height: 24px;
    color: ${lavender};
    box-sizing: border-box;
    ${mediaQueries.tablet}{
      width: 100%;
    }
    p{
      display: flex;
      gap: 10px;
      &+p{
        margin-top: 10px;
      }
      &:before{
        content: "";
        display: flex;
        flex:none;
        margin-top: 10px;
        width: 5px;
        height: 5px;
        background: ${lavender};
        border-radius: 50%;
      }
    }
  }
`;
