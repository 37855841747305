import styled from '@emotion/styled';
import {
  grayDark, green, purple, standardBlack, white
} from 'core/ColorList';
import {
  useCallback, useEffect, useRef, useState
} from 'react';
import { Swiper as SwiperClass } from 'swiper';
import { Autoplay, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import mediaQueries from 'hooks/Media';
import 'swiper/css';
import 'swiper/css/pagination';

export default function SwiperSlides() {
  // 텍스트, 블릿 color 변경 기준
  const REVIEWING_SLIDE_START = 3;
  // 5개 영상 텍스트
  const textPagination = ['01. Read', '02. Read & Listen', '03. Quiz', '04. Vocab Review', '05. Listen Again'];
  const swiperRef = useRef<SwiperClass | null>(null);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const videoRefs = useRef<(HTMLVideoElement | null)[]>([]);

  // Swiper 인스턴스에 'slideChange' 이벤트 리스너를 추가
  useEffect(() => {
    if (swiperRef.current) {
      const swiperInstance = swiperRef.current;
      swiperInstance.on('slideChange', () => {
        setActiveIndex(swiperInstance.realIndex);
      });
      return () => {
        swiperInstance.off('slideChange');
      };
    }
  }, []);

  // IntersectionObserver 콜백 - 슬라이드가 화면에 보일 때 비디오 재생
  const observerCallback = useCallback((entries: any[]) => {
    entries.forEach((entry) => {
      const video = entry.target as HTMLVideoElement;
      if (entry.isIntersecting) {
        video.play();
      } else {
        video.pause();
        video.currentTime = 0;
      }
    });
  }, []);

  // 각 비디오 요소에 대한 참조를 저장
  useEffect(() => {
    const observer = new IntersectionObserver(observerCallback, {
      root: null,
      rootMargin: '0px',
      threshold: 0.5,
    });

    videoRefs.current.forEach((video) => {
      if (video) observer.observe(video);
    });

    return () => {
      videoRefs.current.forEach((video) => {
        if (video) observer.unobserve(video);
      });
    };
  }, [observerCallback]);

  return (
    <SwiperContainer>
      <TextPagination>
        <h3>How It Works</h3>
        <TextPaginationContent>
          <TextPaginationList>
            {textPagination.map((text, index) => {
              // Learning, Reviewing 레벨 디자인을 위한 분기 (pc,mo 달라짐)
              let subtitle = null;
              if (index === 0) {
                subtitle = <strong className={`subTitle learning ${activeIndex >= 0 && activeIndex <= 2  ? 'subShow' : ''}`}>Learning</strong>;
              } else if (index === REVIEWING_SLIDE_START) {
                subtitle = <strong className={`subTitle reviewing ${activeIndex >= REVIEWING_SLIDE_START ? 'subShow' : ''}`}>Reviewing</strong>;
              }
              return (
                <li key={text}>
                  {subtitle}
                  <TextPaginationBullet
                    className={activeIndex === index ? 'active' : ''}
                    onClick={() => {
                      if (swiperRef.current) {
                        swiperRef.current.slideToLoop(index);
                      }
                    }}
                  >
                    {text}
                  </TextPaginationBullet>
                </li>
              );
            })}
          </TextPaginationList>
        </TextPaginationContent>
      </TextPagination>
      <Swiper
        modules={[Autoplay, Pagination]}
        spaceBetween={30}
        pagination={{
          clickable: true,
          renderBullet: (index, className) => {
            // 영상 밑 블릿 색 클래스네임으로 분기
            const colorClass = index < REVIEWING_SLIDE_START ? 'learning' : 'reviewing';
            return `<span class="${className} ${colorClass}">${textPagination[index]}</span>`;
          },
        }}
        onSwiper={(swiper) => {
          swiperRef.current = swiper;
        }}
        onSlideChange={(swiper) => {
          setActiveIndex(swiper.realIndex);
        }}
        className="videoSwiper"
      >
        {textPagination.map((title, index) => (
          <SwiperSlide key={title}>
            {/* 모바일에서 나오는 영상위 타이틀 */}
            <SwiperTitle>{title}</SwiperTitle>
            <SwiperContent>
              <video
                ref={(el) => { videoRefs.current[index] = el; }}
                width="100%"
                muted
                autoPlay
                playsInline
                onEnded={() => {
                  if (index === textPagination.length - 1) {
                    swiperRef.current?.slideTo(0);
                  } else {
                    swiperRef.current?.slideNext();
                  }
                }}
              >
                <source src={`/asset/video/slide-video-${index + 1}.mp4`} type="video/mp4" />
              </video>
            </SwiperContent>
          </SwiperSlide>
        ))}
      </Swiper>
    </SwiperContainer>
  );
}

const SwiperContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: minmax(auto, 1fr);
  column-gap: 20px;
  justify-items: center;
  margin-top:144px;
  ${mediaQueries.tablet}{
    display: block;
    margin-top: 100px;
  }
  h3{
    font-size: 32px;
    color:${white};
    font-weight: 500;
    ${mediaQueries.tablet}{
      text-align: center;
    }
  }
  .swiper {
    align-self: start;
    padding-bottom: 35px;
    width: 300px;
    ${mediaQueries.tablet}{
      overflow: visible;
    }
    .swiper-slide {
      &:nth-of-type(4),
      &:nth-of-type(5){
        strong{
          color: ${green};
        }
      }
    }
    .swiper-pagination {
      bottom: 0;
      z-index: 10;
      .swiper-pagination-bullet {
        text-indent: -9999px;
        width: 8px;
        height: 8px;
        background: ${white};
        opacity: 1;
        margin: 0 4px;
      }
      .swiper-pagination-bullet-active {
        width: 20px;
        border-radius: 10px;
        &.learning {
          background:${purple};
        }
        &.reviewing {
          background:${green};
        }
      }
    }
  }
`;

const SwiperTitle = styled.strong`
  display: none;
  margin-bottom: 38px;
  text-align: center;
  font-size: 24px;
  color: ${purple};
  font-weight: 600;
  ${mediaQueries.tablet}{
    display: block;
  }
`;

const SwiperContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: transparent;
  video{
    border-radius: 20px;
    border: 7px solid ${white};
    box-sizing: border-box;
  }
`;

const TextPagination = styled.div`
  position: relative;
  .subTitle{
    display: inline-block;
    padding: 8px 26px;
    margin: 48px 0 36px;
    font-size: 18px;
    font-weight: 600;
    border-radius: 30px;
    background: ${purple};
    color: ${white};
    ${mediaQueries.tablet}{
      display: none;
      position: absolute;
      top: 70px;
      left: 50%;
      transform: translate3d(-50%,0,0);
      font-size: 15px;
      padding: 8px 42px;
      margin: 0;
      &.subShow{
        display:block;
      }
    }
    &.reviewing {
      background: ${green};
      color: ${standardBlack};
    }
  }
`;

const TextPaginationContent = styled.div`
  ${mediaQueries.tablet}{
    overflow-x: auto;
    margin: 0 -7%;
  }
`;

const TextPaginationList = styled.ul`
  //텍스트버튼 중 active 버튼(4,5번째는 green color)
  li{
    .active{
      color: ${purple};
      &:before{
        background: ${purple};
      }
    }
    &:nth-of-type(4),
    &:nth-of-type(5){
      .active{
        color: ${green};
        &:before{
          background: ${green};
        }
      }
    }
  }
  ${mediaQueries.tablet}{
    display: flex;
    flex-wrap: nowrap;
    margin-top: 110px;
    li{
      flex: none;
      margin-right: 32px;
      &:first-of-type{
        padding-left: 7%;
      }
      &:last-of-type{
        padding-right: 7%;
      }
    }
  }
`;

const TextPaginationBullet = styled.button`
  display: flex;
  align-items: center;
  margin: 20px 0; 
  font-size: 24px;
  font-weight: 500;
  line-height: 38px;
  color: ${grayDark};
  background: none;
  border: none;
  cursor: pointer;
  ${mediaQueries.tablet}{
    display: none;
  }
  &:before{
    content: "";
    display: flex;
    margin-right: 24px;
    width: 17px;
    height: 17px;
    border-radius: 50%;
    background: ${grayDark};
    ${mediaQueries.tablet}{
      display: none;
    }
  }
`;
