import styled from '@emotion/styled';

interface ImageProps {
  src: string;
  alt?: string;
  marginBottom?: string;
  className?: string;
  coverImg?: boolean
}

export default function Image({
  src,
  alt,
  marginBottom,
  className
}: ImageProps) {
  return (
    <ImageContainer className={className} style={{ marginBottom }}>
      <img src={src} alt={alt} />
    </ImageContainer>
  );
}

const ImageContainer = styled.div`
  img {
    width: 100%;
    height: auto;
  }
`;
