import styled from '@emotion/styled';
import Layout from 'components/base/Layout';
import { primary, skyGray, white } from 'core/ColorList';
import mediaQueries from 'hooks/Media';

export default function Footer() {
  return (
    <StyledFooter>
      <Layout>
        <FooterContent>
          <StyledLink
            href="https://info.talktomeinkorean.com/ttmik-stories/privacy-policy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </StyledLink>
          <StyledLink
            href="https://info.talktomeinkorean.com/ttmik-stories/terms-and-conditions"
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms and Conditions
          </StyledLink>
          <StyledLink
            href="https://ttmikux.notion.site/TTMIK-Stories-FAQ-75dfe240016c40159e32d82b511da4f8"
            target="_blank"
            rel="noopener noreferrer"
          >
            FAQ
          </StyledLink>
        </FooterContent>
        <FooterAddress>
          <p>
            G9 주식회사 지나인
          </p>
          <p>
            Business  Number : 110-81-94935
          </p>
          <p>서울시 마포구 월드컵북로 21 6층</p>
          <p>E-commerce Registration Number: 2011-서울서대문-0303</p>
          <p>CEOs : 선현우, 최경은</p>
          <p>
            Email :
            {' '}
            <StyledLinkMail href="mailto:help@talktomeinkorean.com">help@talktomeinkorean.com</StyledLinkMail>
          </p>
        </FooterAddress>
        <FooterCopy>
          <p>© 2023 Talk To Me In Korean. All rights reserved.</p>
          <p>
            Brought to you by
            {' '}
            <a
              href="https://talktomeinkorean.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Talk To Me In Korean
            </a>
          </p>
        </FooterCopy>
      </Layout>
    </StyledFooter>
  );
}

const StyledFooter = styled.footer`
  display: flex;
  align-items: center;
  font-family: "pretendard";
  background: ${primary};
  color: ${white};
  padding: 56px 30px;
  font-size: 14px;
  line-height: 21px;
  ${mediaQueries.tablet}{
    justify-content: center;
    padding: 56px 0;
  }
`;

const FooterContent = styled.div`
  display: flex;
  padding-bottom: 10px;
  margin-bottom: 10px;
  ${mediaQueries.tablet} {
    flex-direction: column;
  }
`;

const FooterAddress = styled.div`
  display: flex;  
  flex-wrap: wrap;
  gap: 6px 16px;
  margin-bottom: 20px;
  color: ${skyGray};
  ${mediaQueries.tablet} {
    flex-direction: column;
    gap: 6px;
  }
`;

const FooterCopy = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${skyGray};
  ${mediaQueries.tablet}{
    flex-direction: column;
    gap: 6px;
  }
  a{
    color: ${skyGray};
    text-decoration: underline;
  }
`;

const StyledLink = styled.a`
  font-size: 17px;
  line-height: 17px;
  font-weight: 500;
  color: ${white};
  text-decoration: none;
  :not(:last-child)::after {
    content: '';
    margin: 0 24px;
    height: 100%;
    border-right: 1px solid ${white};
    text-decoration: none;
  }
  &:hover {
    text-decoration: underline;
  }
  ${mediaQueries.tablet} {
    &:after{
      display: none;
    }
    padding: 16px 0;
    border-bottom: 1px solid ${white};
  }
`;

const StyledLinkMail = styled.a`
  color: ${skyGray};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;
