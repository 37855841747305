import * as amplitude from '@amplitude/analytics-browser';
import { Global } from '@emotion/react';
import '@standalone/font.css';
import reset from 'core/Reset';
import { useEffect } from 'react';
import { AMPLITUDE_API_KEY } from 'services/amplitude';
import EntryRoute from './router/Router';

function AppEntry() {
  return <EntryRoute />;
}
function App() {
  useEffect(() => {
    amplitude.init(AMPLITUDE_API_KEY, {
      defaultTracking: false,
    });

    amplitude.logEvent('Page In');

    const handlePageHide = () => {
      amplitude.setTransport('beacon');
      amplitude.logEvent('Page Leave');
      amplitude.flush();
    };

    window.addEventListener('pagehide', handlePageHide);
    return () => {
      window.removeEventListener('pagehide', handlePageHide);
    };
  }, []);
  return (
    <>
      <Global styles={reset} />
      <AppEntry />
    </>
  );
}

export default App;
