import styled from '@emotion/styled';
import Image from 'components/atoms/Image';
import SectionText from 'components/molecules/SectionText';
import { green3 } from 'core/ColorList';
import mediaQueries from 'hooks/Media';

export default function LearningValue5() {
  return (
    <LearningValue5Container>
      <Image src="/asset/images/pc-topic-screen.webp" className="pc topicImg" alt="app screen"  />
      <Image src="/asset/images/mo-topic-screen.webp" className="mo topicImg" alt="app screen"  />
      <SectionText
        number={5}
        title={(
          <h3>
            Choose from
            <br />
            1,000+ original texts
          </h3>
      )}
        text={(
          <p>
            Improve your skills through extensive
            <br className="pc" />
            {' '}
            reading across various topics.
          </p>
    )}
        color={green3}
      />
    </LearningValue5Container>
  );
}

const LearningValue5Container = styled.div`
  display: flex;
  align-items: center;
  margin-top: 120px;
  ${mediaQueries.tablet}{
    flex-direction: column;
    gap: 24px;
  }
  .topicImg{
    padding-right: 120px;
    width: 544px;
    height: 590px;
    ${mediaQueries.desktop}{
      display: flex;
      align-items: center;
      padding-right: 40px;
    }
    ${mediaQueries.tablet}{
      padding-right: 0;
      width: 390px;
      height: 553px;
    }
  }
`;
