import { standardBlack } from 'core/ColorList';

function ErrorSvg({ ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={props.width ?? 300}
      height={props.height ?? 242}
      viewBox="0 0 300 242"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3284_42456)">
        <path
          d="M133.422 125.568C128.974 125.368 124.959 127.229 122.27 130.318C121.673 131.016 120.544 130.584 120.544 129.654V101.683H83.9695C83.0733 101.683 82.6419 100.62 83.2725 99.9892C85.8944 97.3317 87.4544 93.5779 87.222 89.492C86.8569 82.3499 80.9824 76.603 73.8135 76.3705C65.9476 76.138 59.5088 82.4164 59.5088 90.256C59.5088 94.043 61.0355 97.4645 63.4915 99.9892C64.1221 100.62 63.6907 101.683 62.7945 101.683H26.2197V129.654C26.2197 130.584 27.3482 131.016 27.9456 130.318C30.6339 127.229 34.6498 125.335 39.0972 125.568C46.2662 125.9 52.0411 131.813 52.2735 138.988C52.5058 146.861 46.1998 153.306 38.4003 153.306C34.2184 153.306 30.5012 151.479 27.9456 148.555C27.3482 147.858 26.1865 148.256 26.1865 149.186V177.157H120.478V149.186C120.478 148.256 121.606 147.858 122.237 148.555C124.793 151.479 128.51 153.306 132.692 153.306C140.491 153.306 146.797 146.861 146.565 138.988C146.399 131.813 140.591 125.9 133.422 125.568Z"
          stroke={props.color ?? standardBlack}
          strokeMiterlimit="10"
        />
        <path
          d="M26.2201 101.683H62.795C63.6911 101.683 64.1226 100.62 63.492 99.9892C60.87 97.3317 59.3101 93.578 59.5424 89.4921C59.9075 82.35 65.782 76.6031 72.951 76.3706C80.8169 76.138 87.2557 82.4164 87.2557 90.2561C87.2557 94.0431 85.7289 97.4646 83.2729 99.9892C82.6423 100.62 83.0738 101.683 83.9699 101.683H120.545V73.7463C120.545 72.8161 121.673 72.3843 122.271 73.0819C124.959 76.1713 128.975 78.0647 133.422 77.8322C140.591 77.5 146.366 71.587 146.599 64.4117C146.831 56.5389 140.525 50.0944 132.725 50.0944C128.543 50.0944 124.826 51.9214 122.271 54.8447C121.673 55.5423 120.512 55.1437 120.512 54.2135V26.2432H83.1402C82.2108 26.2432 81.7794 27.3726 82.4764 27.9706C85.563 30.6613 87.4548 34.6808 87.2225 39.1321C86.8906 46.3074 80.9828 52.0875 73.8139 52.3201C65.948 52.5526 59.5092 46.241 59.5092 38.4345C59.5092 34.2489 61.3346 30.5284 64.2553 27.9706C64.9523 27.3726 64.554 26.21 63.6247 26.21L26.2201 26.2432V54.2135C26.2201 55.1437 25.0917 55.5755 24.4943 54.8779C21.8059 51.7885 17.79 49.8951 13.3426 50.1276C6.17368 50.4598 0.398703 56.3728 0.166376 63.5481C-0.0659503 71.4209 6.24006 77.8654 14.0396 77.8654C18.2215 77.8654 21.9387 76.0384 24.4943 73.1151C25.0917 72.4175 26.2533 72.8161 26.2533 73.7463V101.683H26.2201Z"
          stroke={props.color ?? standardBlack}
          strokeMiterlimit="10"
        />
        <path
          d="M214.835 26.243H177.431C176.501 26.243 176.07 25.1136 176.767 24.5156C179.854 21.8249 181.745 17.8054 181.513 13.3541C181.181 6.17877 175.273 0.39867 168.104 0.166138C160.239 -0.066395 153.8 6.2452 153.8 14.0517C153.8 18.2372 155.625 21.9578 158.546 24.5156C159.243 25.1136 158.845 26.2762 157.915 26.2762H120.544V54.2466C120.544 55.1767 121.672 55.6086 122.27 54.911C124.958 51.8216 128.974 49.9281 133.421 50.1607C140.59 50.4928 146.365 56.4058 146.598 63.5811C146.83 71.454 140.524 77.8985 132.724 77.8985C128.543 77.8985 124.825 76.0714 122.27 73.1482C121.672 72.4506 120.511 72.8492 120.511 73.7793V101.75H157.086C157.982 101.75 158.413 102.813 157.783 103.444C155.161 106.101 153.601 109.855 153.833 113.941C154.198 121.083 160.073 126.83 167.242 127.063C175.107 127.229 181.546 120.917 181.546 113.111C181.546 109.324 180.02 105.902 177.563 103.377C176.933 102.746 177.364 101.683 178.26 101.683H214.835V26.243V26.243Z"
          stroke={props.color ?? standardBlack}
          strokeMiterlimit="10"
        />
        <path
          d="M254.397 176.824L222.834 158.388C222.071 157.956 222.237 156.793 223.1 156.561C226.717 155.564 229.937 153.139 231.795 149.485C235.048 143.14 232.891 135.201 226.817 131.414C220.146 127.229 211.417 129.421 207.467 136.164C205.576 139.42 205.144 143.174 206.007 146.562C206.239 147.426 205.31 148.123 204.547 147.691L172.983 129.255L158.911 153.405C158.446 154.202 157.252 154.003 157.086 153.106C156.322 149.087 153.8 145.433 149.85 143.373C143.478 140.051 135.513 142.243 131.696 148.323C127.547 155 129.738 163.736 136.475 167.656C140.093 169.749 144.241 170.048 147.892 168.819C148.755 168.52 149.552 169.45 149.087 170.247L135.015 194.397L166.578 212.834C167.341 213.266 168.271 212.568 168.038 211.704C167.175 208.249 167.607 204.463 169.565 201.174C173.481 194.63 182.111 192.504 188.616 196.424C195.088 200.343 197.278 208.748 193.428 215.292C191.536 218.547 188.483 220.773 185.098 221.67C184.235 221.902 184.069 223.032 184.832 223.497L216.395 241.933L254.397 176.824Z"
          fill={props.fill ?? standardBlack}
        />
        <path
          d="M293.661 183.634C296.151 184.133 299.47 182.704 299.934 180.279C300.399 177.854 297.876 175.33 295.354 174.831C295.354 174.831 290.21 173.237 277.531 175.928C288.285 183.169 293.661 183.634 293.661 183.634Z"
          fill={props.fill ?? standardBlack}
        />
        <path
          d="M289.712 162.308C292.998 160.148 295.022 154.933 292.799 151.545C290.575 148.156 284.999 147.957 281.713 150.083C281.713 150.083 274.279 153.804 263.492 170.778C283.34 167.656 289.712 162.308 289.712 162.308Z"
          fill={props.fill ?? standardBlack}
        />
      </g>
      <defs>
        <clipPath id="clip0_3284_42456">
          <rect width="300" height="242" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default ErrorSvg;
