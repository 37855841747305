import styled from '@emotion/styled';
import Image from 'components/atoms/Image';
import Layout from 'components/base/Layout';
import Download from 'components/organisms/Download';
import {
  lavender,
  orange,
  primary,
  white
} from 'core/ColorList';
import mediaQueries from 'hooks/Media';

export default function HeroSection() {
  return (
    <HeroContainer>
      <Image src="/asset/images/pc-hero-bg.webp" className="pc heroBg" alt="stories bg" />
      <Image src="/asset/images/mo-hero-bg.webp" className="mo heroBg" alt="stories bg" />
      <Layout>
        <HeroTitleContent>
          <TitleBox>
            <Image src="/asset/images/stories-icon.webp" className="storiesIconImg" alt="stories icon" />
            <HeadingTitle>
              Learn Korean. Read,
              Listen, and Level Up
            </HeadingTitle>
            <strong>Extensive Graded Reading by TTMIK</strong>
          </TitleBox>
          <Image src="/asset/images/hero-app-screen.webp" className="heroAppImg" alt="main app screen" />
        </HeroTitleContent>
        <DownLoadContent>
          <Download />
          <Image src="/asset/images/circle.webp" className="circleImg" alt="circle" />
          <Image src="/asset/images/planet.webp" className="planetImg" alt="planet" />
          <Image src="/asset/images/star.webp" className="starImg" alt="star" />
        </DownLoadContent>
      </Layout>
    </HeroContainer>
  );
}

const HeroContainer = styled.section`
  position: relative;
  background: ${primary};
  border-radius: 0 0 50px 50px;
  .heroBg{
    position: absolute;
    width: 100%;
    height: 504px;
    ${mediaQueries.tablet}{
      height: 374px;
    }
    img{
      object-position: bottom;
      object-fit: cover;
      height: 100%;
    }
  }
`;

const HeroTitleContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 100px 0 120px;
  ${mediaQueries.tablet}{
    padding: 80px 0 40px;
    flex-direction: column;
    align-items: flex-start;
  }
  .heroAppImg{
    display: flex;
    align-items: center;
    padding-right: 48px;
    width: 360px;
    height: 614px;
    ${mediaQueries.desktop}{
      margin: 134px auto 0;
      padding-right: 0;
      width: 296px;
      height: 504px;
    }
  }
`;

const HeadingTitle = styled.h1`
  margin-top: 25px;
  font-family: "futura-pt";
  font-weight: 700;
  font-size: 48px;
  line-height: 68px;
  color: ${white};
  ${mediaQueries.tablet}{
    font-size: 32px;
    line-height: 45px;
  }
`;

const TitleBox = styled.div`
  width: 52%;
  ${mediaQueries.tablet}{
    width: 100%;
  }
  .storiesIconImg{
    margin-left: -10px;
    width: 105px;
    height: 105px;
  }
  strong{
    display: block;
    margin-top: 80px;
    font-family: "futura-pt";
    font-size: 32px;
    color: ${orange};
    line-height: 45px;
    ${mediaQueries.tablet}{
        margin-top: 13px;
        font-size: 24px;
        line-height: 36px;
      }
  }
`;

const DownLoadContent = styled.div`
  position: relative;
  padding-bottom: 120px;
  ${mediaQueries.tablet}{
    padding-bottom: 40px;
  }
  .circleImg{
    position: absolute;
    top: -368px;
    right: -156px;
    width: 106px;
    height: 106px;
    ${mediaQueries.desktop}{
      display: none;
    }
  }
  .planetImg{
    position: absolute;
    top: -145px;
    left: -11vw;
    width: 281px;
    height: 147px;
    ${mediaQueries.desktop}{
      left: -100px;
    }
    ${mediaQueries.tablet}{
      top: -670px;
      left: auto; 
      right: -50px;
      width: 184px;
      height: 94px;
    }
  }
  .starImg{
    position: absolute;
    right: 0;
    bottom: 238px;
    width: 126px;
    height: 126px;
    ${mediaQueries.desktop}{
      right: 30%;
      margin-right: -320px;
      bottom: 150px;
    }
    ${mediaQueries.tablet}{
      display: none;
    }
  }
  strong{
    color: ${lavender};
  }
`;
