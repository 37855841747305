import FeedbackSection from 'components/templates/FeedbackSection';
import Footer from 'components/templates/Footer';
import HeroSection from 'components/templates/HeroSection';
import LearningGuideSection from 'components/templates/LearningGuideSection';
import LearningValueSection from 'components/templates/LearningValueSection';
import QnASection from 'components/templates/QnASection';

export default function LandingPage() {
  return (
    <>
      {/* <Header /> */}
      {/* <TopBannerSection /> */}
      <HeroSection />
      <LearningValueSection />
      <FeedbackSection />
      <LearningGuideSection />
      <QnASection />
      <Footer />
    </>
  );
}
