// color
export const primary = '#031B30';
export const orange = '#F45925';
export const purple = '#722EFA';
export const lavender = '#DDCCFF';
export const blueGradient = 'linear-gradient(180deg,#D6E9F5,#DDCCFF)';
export const lavenderGradient = 'linear-gradient(180deg, #D6E9F5 0%, #D6E9F5 5%, #DDCCFF 40%, #FFF 100%);';
export const sky = '#D6E9F5';
export const gray = '#CCCCCC';
export const green = '#72f3b5';
export const green2 = '#2CED90';
export const green3 = '#00AC5D';
export const skyGray = '#B8D1E0';

export const grayDark = '#666';
export const standardBlack = '#333';
export const skyBlue = '#00A8E8';
export const white = '#fff';
export const transparent = 'transparent';

export type UiColorList =
    | typeof primary
    | typeof orange
    | typeof purple
    | typeof lavender
    | typeof blueGradient
    | typeof lavenderGradient
    | typeof sky
    | typeof gray
    | typeof green
    | typeof green2
    | typeof green3
    | typeof skyGray
    | typeof grayDark
    | typeof standardBlack
    | typeof skyBlue
    | typeof transparent
    | typeof white;
// base layout
