import styled from '@emotion/styled';
import Image from 'components/atoms/Image';
import Layout from 'components/base/Layout';
import FeedBack from 'components/molecules/FeedBack';
import { purple } from 'core/ColorList';
import mediaQueries from 'hooks/Media';

export default function FeedbackSection() {
  return (
    <FeedbackSectionContainer>
      <Layout>
        <h2>
          Feedback from
          <br />
          our customers
        </h2>
        <FeedbackContent>
          <Image src="/asset/images/quotes.webp" className="quotesImg1" alt="" />
          <FeedbackBox>
            <FeedBack
              src="/asset/images/review-people-1.webp"
              name="Sienna"
              text="It's become a part of my daily routine.
                  This app provides short and fun stories that
                  I can read when I have a short break, so I can quickly
                  finish at least one story in a few minutes."
            />
            <FeedBack
              src="/asset/images/review-people-2.webp"
              name="Chris"
              text="This app covers various topics in different contexts,
                  and it provides users with useful expressions and daily
                  vocabulary that I can use in real-life conversations with my
                  friends."
            />
            <FeedBack
              src="/asset/images/review-people-3.webp"
              name="May"
              text="This app is a game-changer. I am preparing for the TOPIK exam at this moment,
                    and this app has become an invaluable asset in my Korean learning journey. I love it!"
            />
          </FeedbackBox>
          <Image src="/asset/images/quotes.webp" className="quotesImg2" alt="" />
        </FeedbackContent>
      </Layout>
    </FeedbackSectionContainer>
  );
}

const FeedbackSectionContainer = styled.section`
  padding: 240px 0;
  ${mediaQueries.tablet}{
    padding: 40px 0 200px;
  }
  h2{
    font-family: "futura-pt";
    font-size: 42px;
    font-weight: 700;
    line-height: 50px;
    text-align: center;
    color: ${purple};
    ${mediaQueries.tablet}{
      font-size: 28px;
      line-height: 32px;
    }
  }
  .quotesImg1{
    position: absolute;
    width: 583px;
    height: 433px;
    top: 80px;
    right: 50%;
    ${mediaQueries.tablet}{
      top: 65px;
      margin-right: -20px;
    }
  }
  .quotesImg2{
    position: absolute;
    width: 583px;
    height: 433px;
    left: 50%;
    bottom: -80px;
    transform: rotate(180deg);
    ${mediaQueries.tablet}{
      margin-left: -20px;
    }
  }
`;

const FeedbackContent = styled.div`
  position: relative;
  padding: 165px 80px 0;
  ${mediaQueries.desktop}{
    padding: 165px 0 0;
  }
  ${mediaQueries.tablet}{
    padding: 150px 0 0;
  }
`;

const FeedbackBox = styled.div`
  position: relative;
  >div{
    &:nth-child(2){
      margin: -25px 0 25px auto;
      ${mediaQueries.tablet}{
        margin: 25px 0;
      }
    }
  }
`;
