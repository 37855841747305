import styled from '@emotion/styled';
import { white } from 'core/ColorList';
import mediaQueries from 'hooks/Media';
import { ReactNode } from 'react';

interface Props extends StyledProps {
  title : ReactNode;
  text: ReactNode;
  number: number;
  className?: string;
}

interface StyledProps {
  color: string;
}

export default function SectionText({
  title, text, number, className, color
}:Props) {
  return (
    <SectionTextContainer className={className} color={color}>
      <span>{number}</span>
      {title}
      {text}
    </SectionTextContainer>
  );
}

const SectionTextContainer = styled.div<StyledProps>`
  span{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;
    width: 42px;
    height: 42px;
    color: ${white};
    background: ${(props) => props.color};
    font-size: 24px;
    border-radius: 50%;
  }
  h3{
      font-size: 40px;
      line-height: 52px;
      font-weight: 700;
      color: ${(props) => props.color};
      ${mediaQueries.tablet}{
        font-size: 30px;
        line-height: 39px;
      }
  }
  p{
    margin-top: 16px;
    font-size: 22px;
    line-height: 30px;
    ${mediaQueries.tablet}{
      font-size: 18px;
      line-height: 23px;
    }
  }
`;
