import styled from '@emotion/styled';
import Image from 'components/atoms/Image';
import SectionText from 'components/molecules/SectionText';
import { green3 } from 'core/ColorList';
import mediaQueries from 'hooks/Media';

export default function LearningValue2() {
  return (
    <LearningValue2Container>
      <Image src="/asset/images/particle-1.webp" className="particleImg" alt="" />
      <SectionText
        number={2}
        className="zIndexLayer"
        title={(
          <h3>
            Read shorter,
            <br />
            more often
          </h3>
        )}
        text={(
          <p>
            Build your habit with texts
            <br className="pc" />
            {' '}
            you can finish in minutes.
          </p>
        )}
        color={green3}
      />
      <Image src="/asset/images/app-screen.webp" className="appScreenImg" alt="app screen" />
      <Image src="/asset/images/artwork.webp" className="artworkImg" alt="" />
    </LearningValue2Container>
  );
}

const LearningValue2Container = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 170px;
  ${mediaQueries.tablet}{
    flex-direction: column-reverse;
    align-items: flex-end;
    margin-top: 120px;
  }
  .particleImg{
    position: absolute;
    top: -45px;
    left: 50%;
    transform: translate3d(-53%,0,0);
    width: 1390px;
    height: 650px;
    ${mediaQueries.tablet}{
      display: none;
    }
  }
  .zIndexLayer{
    position: relative;
    z-index: 1;
    margin-top: 0;
    ${mediaQueries.tablet}{
      margin-top: 12px;
      width: 100%;

    }
  }
  .appScreenImg{
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    width: 383px;
    height: 590px;
    padding-right: 85px;
    ${mediaQueries.desktop}{
      padding-right: 0;
      width: 305px;
      height: 470px;
    }
    ${mediaQueries.tablet}{
      right: -73px;
    }
  }
  .artworkImg{
    position: absolute;
    left: 50%;
    bottom: -100px;
    margin-left: -180px;
    width: 232px;
    height: 353px;
    ${mediaQueries.desktop}{
      margin-left: -100px;
    }
    ${mediaQueries.tablet}{
      display: none;
    }
  }
`;
