import styled from '@emotion/styled';
import Image from 'components/atoms/Image';
import {
  white
} from 'core/ColorList';
import { AnchorHTMLAttributes } from 'react';

interface Props extends AnchorHTMLAttributes<HTMLAnchorElement>, StyledProps {
  text: string;
  imgUrl?: string;
  href: string;
  alt?: string;
}
interface StyledProps {
  imgWidth?: number;
  imgHeight?: number;
}

export function Anchor({
  color,
  imgUrl,
  text,
  href,
  alt,
  ...props
}: Props) {
  return (
    <AnchorContainer
      color={color}
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      {...props}
    >
      {imgUrl && <Image src={imgUrl} className="img" alt={alt} />}
      {text}
    </AnchorContainer>
  );
}

const AnchorContainer = styled.a<StyledProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 310px;
  height: 70px;
  font-size: 20px;
  color: ${(props) => props?.color ?? white};
  border: 1px solid ${white};
  border-radius: 100px;
  box-sizing: border-box;
  .img {
    width: ${(props) => props.imgWidth}px;
    height: ${(props) => props.imgHeight}px;
    margin-right: 16px;
  }
`;
