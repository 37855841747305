import styled from '@emotion/styled';
import Layout from 'components/base/Layout';
import DropDown from 'components/molecules/DropDown';
import { sky } from 'core/ColorList';
import mediaQueries from 'hooks/Media';

function QnA() {
  return (
    <QnAContainer>
      <Layout>
        <h2>
          Questions & Answers
        </h2>
        <DropDownContainer>
          <DropDown
            title="What is TTMIK Stories?"
            desc={(
              <p>
                TTMIK Stories is a Korean graded reading app designed to help
                you improve your skills through extensive reading. Use our library of over 1,000 original texts to
                learn Korean grammar, vocabulary, and culture naturally in context.
                <br />
                <br />
                Talk To Me In Korean, or TTMIK, has been helping people learn Korean for over 10 years.
                More than 1.5 million people in 190 countries have chosen to study using over 40 published books and
                1,700 online lessons in our educational catalogue.
              </p>
          )}
          />
          <DropDown
            title="What is graded reading?"
            desc={(
              <p>
                Graded reading helps learners gradually improve their language skills through comprehensible
                input — in other words, by offering material written at just the right difficulty.
                Stories are divided into graded levels to make sure you’re stimulated but never overwhelmed.
              </p>
          )}
          />
          <DropDown
            title="What can I find on TTMIK Stories?"
            desc={(
              <p>
                TTMIK Stories offers a vast library of graded texts for all tastes and skill levels.
                Texts are divided into two categories: series, or sets of interconnected stories,
                and articles, which are stand-alone stories or texts with interesting information.
                <br />
                <br />
                Each story features a professional audio track, a review quiz, and a vocab quiz to help you retain what you read.
              </p>
          )}
          />
          <DropDown
            title="How are the stories graded?"
            desc={(
              <p>
                Our team of Korean language experts has designed 10 graded levels based on vocabulary, grammar, sentence
                structure, and text length.
                <br />
                Beginner-level stories (levels 1-3) are 100-200 characters long,
                while intermediate (levels 4-7) and advanced (levels 8-10) stories are around 300 and 400 characters respectively.
                <br />
                <br />
                Each story makes use of grammar and vocabulary from the corresponding Talk To Me In Korean core curriculum level
                as well as common vocabulary for the TOPIK exam.
              </p>
          )}
          />
          <DropDown
            title="How do I know my level?"
            desc={(
              <p>
                Simple — complete a quick in-app test. TTMIK Stories will help you choose the level that’s right for you.
              </p>
          )}
          />
          <DropDown
            title="Is a free trial available?"
            desc={(
              <p>
                Yes! Your 7-day free trial will begin as soon as you sign up.
                After seven days, you’ll need to subscribe to continue accessing TTMIK Stories.
                Your saved progress will be restored once you subscribe.
                Only one free trial is offered per account.
              </p>
          )}
          />
          <DropDown
            title="I have an active TTMIK Course Subscription. Can I use TTMIK Stories with this subscription?"
            desc={(
              <p>
                These are two different services that we offer.
                You need a separate subscription to TTMIK Stories to use it.
                <br />
                <br />
                The TTMIK Course Subscription is for taking courses on talktomeinkorean.com, and the TTMIK Stories
                Subscription is for our graded Korean reading app, TTMIK Stories.
                You can subscribe to TTMIK Stories through in-app purchases on the Google Play Store or App Store.
              </p>
          )}
          />
        </DropDownContainer>
      </Layout>
    </QnAContainer>
  );
}

const QnAContainer = styled.section`
  background: ${sky};
  padding: 120px 0;
  ${mediaQueries.tablet}{
    padding: 80px 0;
  }
  h2 {
    margin-bottom: 40px;
    font-size: 29px;
    font-weight: 700;
    line-height: 37px;
    text-align: center;
  }
`;

const DropDownContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default QnA;
