import styled from '@emotion/styled';
import mediaQueries from 'hooks/Media';
import { ReactNode } from 'react';

interface Props extends StyledProps {
  title: ReactNode;
  text: ReactNode;
}

interface StyledProps {
  textColor: string;
}

export default function SectionTitle({
  title, text, textColor
}:Props) {
  return (
    <SectionTitleContainer textColor={textColor}>
      {title}
      {text}
    </SectionTitleContainer>
  );
}

const SectionTitleContainer = styled.div<StyledProps>`
    text-align: center;
    h2{
      font-size: 64px;
      font-family: "futura-pt";
      font-weight: 700;
      line-height: 77px;
      color: ${(props) => props.textColor};
      ${mediaQueries.tablet}{
        font-size: 40px;
        line-height: 48px;
      }
    }
    p{
      margin-top: 24px;
      font-size: 24px;
      line-height: 36px;
      font-weight: 600;
      color: ${(props) => props.textColor};
      ${mediaQueries.tablet}{
        font-size: 20px;
        line-height: 26px;
      }
    }
`;
