import styled from '@emotion/styled';
import Image from 'components/atoms/Image';
import { white } from 'core/ColorList';
import mediaQueries from 'hooks/Media';

interface Props {
  src: string;
  name: string;
  text: string;
}

export default function FeedBack({ src, name, text }: Props) {
  return (
    <FeedBackContainer>
      <ImgContent>
        <Image src={src} className="peopleImg" alt={name} />
        <span>{name}</span>
      </ImgContent>
      <TextContent>
        <p>{text}</p>
      </TextContent>
    </FeedBackContainer>
  );
}

const FeedBackContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 32px 40px;
  width: 625px;
  border-radius: 30px;
  background: ${white};
  box-sizing: border-box;
  box-shadow: 0px 4px 24px 0px rgba(114, 46, 250, 0.3);
  ${mediaQueries.tablet}{
    flex-direction: column;
    padding: 32px; 
    width: 100%
  }
`;

const ImgContent = styled.div`
  text-align: center;
  .peopleImg{
    width: 120px;
    height: 120px;
    border-radius: 50%;
  }
  span{
    display: block;
    margin-top: 10px;
    font-size: 18px;
    font-weight: 500;
  }
`;

const TextContent = styled.div`
  margin-left: 32px;
  font-size: 22px;
  font-weight: 500;
  line-height: 30px;
  ${mediaQueries.tablet}{
    margin-left: 0;
    margin-top: 32px;
    font-size: 20px;
  }
`;
