import styled from '@emotion/styled';
import Image from 'components/atoms/Image';
import SectionText from 'components/molecules/SectionText';
import { purple } from 'core/ColorList';
import mediaQueries from 'hooks/Media';

export default function LearningValue1() {
  return (
    <LearningValue1Container>
      <ValueBox>
        <Image src="/asset/images/level-test-result.webp" className="levelImg" alt="level completion certificate"  />
        <SectionText
          number={1}
          title={(
            <h3>
              Study at your level
            </h3>
        )}
          text={(
            <p>
              Learn Korean naturally with stories
              <br className="pc" />
              {' '}
              you can follow.
            </p>
        )}
          color={purple}
        />
        <Image src="/asset/images/balloon.webp" className="balloonImg" alt="" />
      </ValueBox>
    </LearningValue1Container>
  );
}

const LearningValue1Container = styled.div`
  margin-top: 126px;
  ${mediaQueries.tablet}{
    margin-top: 64px;
  }
`;

const ValueBox = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 120px;
  ${mediaQueries.tablet}{
    flex-direction: column;
    align-items: center;
    gap: 24px;
  }
  .levelImg{
    width: 552px;
    height: 405px;
  }
  .balloonImg{
    position: absolute;
    top: -70px;
    right: -40px;
    width: 280px;
    height: 499px;
    ${mediaQueries.desktop}{
      right: -140px;
    }
    ${mediaQueries.tablet}{
      display: none;
    }
  }
`;
